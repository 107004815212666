// Service TypeScript files should be AUTO-GENERATED by the Typewriter Visual Studio plugin. Do not modify this file.
import BaseApi from './BaseApi';
import RoleDTO from '../models/RoleDTO';

export class RoleApiService extends BaseApi {

    // get: api/role/getRoles
    public getRoles(signal?: AbortSignal): Promise<RoleDTO[]> {
        let url = `api/role/getRoles`;

        url = url.replace(/null|undefined/gi, '');
        const _signal = signal ?? new AbortController().signal;
        return this.SendRequest<any, RoleDTO[]>(null, 'get', url, true, false, _signal);
    }

    // get: api/role/getRoles/${userID}
    public getRolesByUser(userID: number, signal?: AbortSignal): Promise<RoleDTO[]> {
        let url = `api/role/getRoles/${userID}`;

        url = url.replace(/null|undefined/gi, '');
        const _signal = signal ?? new AbortController().signal;
        return this.SendRequest<any, RoleDTO[]>(null, 'get', url, true, false, _signal);
    }
}
var service = new RoleApiService();
export default service;
